import './FunctionPart.scss'

import { Link } from 'gatsby'
import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import PuxVideo, { PuxVideoType } from 'Shared/components/PuxVideo/PuxVideo'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import PuxLightboxGallery from '../../PuxLightboxGallery/PuxLightboxGallery'

export interface FunctionPartType {
  contentType: 'EasySoftwareFunction'
  contentItemId: string
  path: string
  displayText: string
  functionPerex: PuxWysiwygHtmlBodyType
  functionContent: PuxWysiwygHtmlBodyType
  functionImage: PuxMediaSelectorType
  functionGallery: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

const rootClass = `function-part`
const itemRootClass = `function`

const FunctionPart: FunctionComponent<FunctionPartType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  function renderMediaComponent() {
    if (
      typeof props.puxVideo.videoYTID?.html !== `string` ||
      props.puxVideo.videoYTID?.html === ``
    ) {
      if (props.functionImage) {
        return (
          <div onClick={() => setIsOpened(true)}>
            <PuxMediaSelector {...props.functionImage} />
          </div>
        )
      } else {
        return null
      }
    }
    if (
      typeof props.puxVideo.videoYTID?.html === `string` &&
      props.puxVideo.videoYTID?.html !== ``
    ) {
      return (
        <PuxVideo
          videoStructuredDataDescription={
            props.puxVideo.videoStructuredDataDescription
          }
          videoStructuredDataContentUrl={
            props.puxVideo.videoStructuredDataContentUrl
          }
          videoStructuredDataName={props.puxVideo.videoStructuredDataName}
          videoStructuredDataUploadDate={
            props.puxVideo.videoStructuredDataUploadDate
          }
          videoThumbnail={props.functionImage.mediaSelectorImage}
          videoYTID={props.puxVideo.videoYTID}
        />
      )
    }

    return null
  }

  const getTitle = (): JSX.Element => {
    const activeProject = process.env.PROJECT

    if (activeProject === `EasyProjectGatsby`) {
      return <h3 className={`${itemRootClass}-title`}>{props.displayText}</h3>
    }

    return <h2 className={`${itemRootClass}-title`}>{props.displayText}</h2>
  }

  return (
    <div className={`pux-repeater-item ${rootClass}-wrapper`}>
      <div className={`${rootClass}`}>
        <div className={`${rootClass}-items ${itemRootClass}`}>
          <div className={`${itemRootClass}-grid`}>
            <div className={`${itemRootClass}-text`}>
              {getTitle()}
              <div className={`${itemRootClass}-perex`}>
                <PuxWysiwyg content={props.functionPerex} />
              </div>
              <div>
                <button
                  className='pux-button pux-button-link pux-button-primary'
                  onClick={() => setIsOpened(!isOpened)}
                >
                  {isOpened
                    ? t`Redmine.Function.LessInfo`
                    : t`Redmine.Function.MoreInfo`}
                </button>
              </div>
            </div>
            <div className={`${rootClass}-image`}>{renderMediaComponent()}</div>
          </div>
          <Collapse isOpened={isOpened}>
            <div className={`${itemRootClass}-grid`}>
              <div className={`${itemRootClass}-content`}>
                <PuxWysiwyg content={props.functionContent} />
              </div>
              <div className={`${itemRootClass}-gallery`}>
                <PuxLightboxGallery {...props.functionGallery} />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export default FunctionPart
